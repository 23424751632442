/*https://www.w3schools.com/colors/colors_picker.asp*/
/*https://coolors.co/2c972c*/
.puzzle-layout {
    --square-size: 'set from js';
    --num-cols: 'set from js';
    --num-rows: 'set from js';
    --puzzle-spots: 'set from js';
    --puzzle-visitable-spots: 'set from js';
    --row-gap: 'set from js';
    --col-gap: 'set from js';
}

.puzzle-layout {
    /*--grid-gap: calc(var(--grid-gap-pct) * var(--square-size) * 0.01);*/
    position: absolute;
    top:0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.puzzle-layout_group {
    margin: 0 auto;
    width: calc(var(--num-cols) * (var(--square-size) + var(--col-gap)) - var(--col-gap));
    height: calc(var(--num-rows) * (var(--square-size) + var(--row-gap)) - var(--row-gap));
}

.puzzle-layout--visible {
    opacity: 1;
}

.puzzle-layout--no-puzzle,
.puzzle-layout--hidden {
    opacity: 0;
}

.user-facing-game--animations-enabled .puzzle-layout {
    transition: opacity 1000ms ease;
}
/*.user-facing-game--animations-enabled .puzzle-layout--hidden {*/
/*    animation: fade-out 1000ms ease-in;*/
/*}*/

/* puzzle-spot__outer has the clickish handlers on it. */
.puzzle-layout--visible .puzzle-spot--current-location .puzzle-spot__outer,
.puzzle-layout--visible .puzzle-spot--possible-destination .puzzle-spot__outer,
.puzzle-layout--visible .puzzle-spot--possible-redo-location .puzzle-spot__outer {
    pointer-events: auto;
}

.puzzle-layout--visible .puzzle-spot--possible-destination .puzzle-spot__outer {
    cursor: pointer;
}

.puzzle-layout--visible .puzzle-spot--possible-redo-location .puzzle-spot__outer {
    cursor: crosshair;
    /*cursor: url("../img/undo-1.svg"), crosshair;*/
}

/*.puzzle-layout--hidden .puzzle-spot {*/
/*    animation-play-state: paused !important;*/

/*}*/

.puzzle-layout--incomplete {

}

.puzzle-layout--dead-end {

}

.puzzle-layout--completed {

}

.puzzle-layout--no-moves-made {

}

.puzzle-layout--some-moves-made {

}

.puzzle-layout--user-performed-last-move {

}


.puzzle-layout__grid,
.puzzle-layout__shadow-grid {
    position: absolute;
    display: grid;
}

.puzzle-layout__shadow-grid {
    --pxdiff: 5px;
    --shadow-grid-square-size: calc(var(--square-size) - var(--pxdiff));
    --shadow-grid-column-gap: calc(var(--col-gap) + var(--pxdiff) * (var(--num-cols) / (var(--num-cols) - 1)));
    --shadow-grid-row-gap: calc(var(--row-gap) + var(--pxdiff) * (var(--num-rows) / (var(--num-rows) - 1)));
    grid-template-columns: repeat(var(--num-cols), var(--shadow-grid-square-size));
    grid-template-rows: repeat(var(--num-rows), var(--shadow-grid-square-size));
    column-gap: var(--shadow-grid-column-gap);
    row-gap: var(--shadow-grid-row-gap);
}

.puzzle-layout__grid {
    grid-template-columns: repeat(var(--num-cols), var(--square-size));
    grid-template-rows: repeat(var(--num-rows), var(--square-size));
    column-gap: var(--col-gap);
    row-gap: var(--row-gap);
}

.puzzle-spot {
    --loc-column: 'set from js';
    --loc-row: 'set from js';
    --random-dir-x: 'set from js';
    --random-dir-y: 'set from js';

    /* This is important for laying out the 3d theme. */
    z-index: calc(100 + var(--loc-row) - var(--loc-column));
}

.puzzle-spot--void {
    visibility: hidden;
    animation: none !important;
}


.puzzle-spot__outer {
}

.puzzle-spot__middle {
    /* Only the puzzle-spot__outer guy can be touched, which is essential for touch-dragging to hit more spots. */
    pointer-events: none;
}

.puzzle-spot__inner {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*.puzzle-spot__img {*/
/*    !*height: 100%;*!*/
/*    width: 100%;*/
/*    opacity: .7;*/
/*}*/

/*.puzzle-spot__img--slightly-smaller {*/
/*    !*height: 93%;*!*/
/*    !*width: 93%;*!*/
/*    padding: 5%;*/
/*}*/

