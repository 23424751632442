body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    touch-action: none;
}

#root {
    height: 100%;
}

* {
    /*filter: none !important;*/
    /*box-shadow: none !important;*/
    /*animation: none !important;*/
    /*transition: none !important;*/
}

*, *:before, *:after {
    /*box-sizing: inherit;*/
    box-sizing: border-box;
}