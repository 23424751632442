
.vars-from-user-facing-game {
    --theme-major-hue: 'set from js';
    --theme-minor-hue: 'set from js';
    --theme-complement-hue: 'set from js';

    --theme-random-number-1: 'set from js';
    --theme-random-number-2: 'set from js';
    --world-move-transition-time: 'set from js';

    --contraption-bg-color: 'calculated up there';
}

.puzzle-layout--original-theme {
    /* TODO: Look into this, from Susan, next time you're monkjeying with these colors
        https://paletton.com/#uid=a2y5m0kveTpj4UOpaU7QgRIOMtX
    */

    --border-color: black;

    --gold-border-color: rgb(133, 117, 78);
    --gold-bg-color: #ffdf00;

    --possible-destination-bg-color: hsl(var(--theme-major-hue), 70%, 80%);

    --current-location-bg-color: hsl(90, 95%, 50%);
    --current-location-deadend-bg-color: firebrick;
    --visits-done-bg-color: hsl(var(--theme-major-hue), 80%, 20%);
    --visits-undone-bg-color: hsl(var(--theme-minor-hue), 60%, 97%);
    /*--possible-destination-bg-color: #cce5cc;*/
    --start-explosion-color: var(--visits-undone-bg-color);
    --required-possible-move-bg-color: hsl(var(--theme-major-hue), 20%, 85%);
    --optional-possible-move-bg-color: var(--required-possible-move-bg-color);


    /*--redo-outline-color: var(--current-location-bg-color);*/
    --redo-outline-color: hsla(90, 95%, 50%, .6);
    --redo-outline-width: calc(var(--square-size) * 0.05);
    --redo-outline-offset: calc(var(--square-size) * -0.16);

    --drop-shadow-distance: calc(var(--square-size) * .12);
    --drop-shadow-color: hsla(0, 0%, 0%, .7);
    --drop-shadow: drop-shadow(0px var(--drop-shadow-distance) var(--drop-shadow-distance) var(--drop-shadow-color));

    --shrink-size: 80%;
    --border-radius: 10%;

    /* TODO: There's something funny with these borders maybe. A bit of white showing up, maybe from another
        element, but the shadow doesn't end up looking perfect.*/
    --standard-border-width: calc(var(--square-size) * .04);

    --bg-color-transition-time: 220ms;
    --explode-animation-time: 800ms;
    --checkmark-background: url("../img/checkmark-2.svg");
}

.puzzle-layout--original-theme .puzzle-spot,
.puzzle-layout--original-theme .puzzle-spot__outer,
.puzzle-layout--original-theme .puzzle-spot__middle,
.puzzle-layout--original-theme .puzzle-spot__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: var(--border-radius);
}

.puzzle-layout--original-theme .puzzle-spot {
    position: relative;
    height: var(--square-size);
    width: var(--square-size);
}

.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme .puzzle-spot,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme .puzzle-spot__outer,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme .puzzle-spot__middle,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme .puzzle-spot__inner {
    transition: background-color var(--bg-color-transition-time) ease;
}

/* https://tobiasahlin.com/blog/how-to-animate-box-shadow/ */
.puzzle-layout--original-theme .puzzle-spot:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    border-radius: var(--border-radius);
    /*box-shadow: rgba(0, 0, 0, 0.3) 0 19px 38px,*/
    /*rgba(0, 0, 0, 0.22) 0 15px 12px;*/
}

.puzzle-layout--original-theme .puzzle-spot--gold .puzzle-spot__inner {
    background-color: var(--gold-bg-color);
    --border-color: var(--gold-border-color);
    border: var(--standard-border-width) solid var(--border-color);
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-1.puzzle-spot--remaining-visits-0 .puzzle-spot__inner,
.puzzle-layout--original-theme .puzzle-spot--required-visits-2.puzzle-spot--remaining-visits-0 .puzzle-spot__inner,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-0 .puzzle-spot__inner {
    background-color: var(--visits-done-bg-color);
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-1.puzzle-spot--remaining-visits-1 .puzzle-spot__inner,
.puzzle-layout--original-theme .puzzle-spot--required-visits-2.puzzle-spot--remaining-visits-1 .puzzle-spot__inner,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-1 .puzzle-spot__inner,
.puzzle-layout--original-theme .puzzle-spot--required-visits-2.puzzle-spot--remaining-visits-2 .puzzle-spot__inner,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-2 .puzzle-spot__inner,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-3 .puzzle-spot__inner {
    background-color: var(--visits-undone-bg-color);
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-2.puzzle-spot--remaining-visits-0 .puzzle-spot__middle,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-0 .puzzle-spot__middle,
.puzzle-layout--original-theme .puzzle-spot--required-visits-2.puzzle-spot--remaining-visits-1 .puzzle-spot__middle,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-1 .puzzle-spot__middle {
    background-color: var(--visits-done-bg-color);
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-2.puzzle-spot--remaining-visits-2 .puzzle-spot__middle,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-2 .puzzle-spot__middle,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-3 .puzzle-spot__middle {
    background-color: var(--visits-undone-bg-color);
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-2.puzzle-spot--remaining-visits-0.puzzle-spot--current-location .puzzle-spot__middle,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-0.puzzle-spot--current-location .puzzle-spot__middle {
    background-color: var(--visits-done-bg-color);
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-2.puzzle-spot--remaining-visits-1.puzzle-spot--current-location .puzzle-spot__middle,
.puzzle-layout--original-theme .puzzle-spot--required-visits-2.puzzle-spot--remaining-visits-2.puzzle-spot--current-location .puzzle-spot__middle,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-1.puzzle-spot--current-location .puzzle-spot__middle,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-2.puzzle-spot--current-location .puzzle-spot__middle,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-3.puzzle-spot--current-location .puzzle-spot__middle {
    background-color: var(--visits-undone-bg-color);
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-0 .puzzle-spot__outer,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-1 .puzzle-spot__outer,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-2 .puzzle-spot__outer {
    background-color: var(--visits-done-bg-color);
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-3 .puzzle-spot__outer {
    background-color: var(--visits-undone-bg-color);
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-0.puzzle-spot--current-location .puzzle-spot__outer,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-1.puzzle-spot--current-location .puzzle-spot__outer {
    background-color: var(--visits-done-bg-color);
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-2.puzzle-spot--current-location .puzzle-spot__outer,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3.puzzle-spot--remaining-visits-3.puzzle-spot--current-location .puzzle-spot__outer {
    background-color: var(--visits-undone-bg-color);
}

/* These live under the normal squares and provide shadows that don't ever overlap the squares' bodies. */
.puzzle-layout--original-theme .puzzle-layout__shadow {
    /*height: var(--square-size);*/
    /*width: var(--square-size);*/
    /* Not visible, just need something opaque to drop the shadow. */
    /*background-color: white;*/

    border-radius: var(--border-radius);
    /*filter: var(--drop-shadow);*/
    box-shadow: var(--standard-shadow);
}

.puzzle-layout--original-theme .puzzle-layout__filler {
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-2 .puzzle-spot__inner,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3 .puzzle-spot__inner {
    border: var(--standard-border-width) solid var(--border-color);
    filter: var(--drop-shadow);
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-3 .puzzle-spot__middle {
    border: var(--standard-border-width) solid var(--border-color);
    filter: var(--drop-shadow);
}

/* These are the root elements, displayed large, and should look like they have a shadow on the play surface,
    which is handled by .puzzle-layout__shadow */
.puzzle-layout--original-theme .puzzle-spot--required-visits-1 .puzzle-spot__inner,
.puzzle-layout--original-theme .puzzle-spot--required-visits-2 .puzzle-spot__middle,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3 .puzzle-spot__outer {
    border: var(--standard-border-width) solid var(--border-color);
    /*filter: var(--drop-shadow);*/
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-2 .puzzle-spot__inner,
.puzzle-layout--original-theme .puzzle-spot--required-visits-3 .puzzle-spot__inner {
    margin: auto;
    height: var(--shrink-size);
    width: var(--shrink-size);
}

.puzzle-layout--original-theme .puzzle-spot--required-visits-3 .puzzle-spot__middle {
    margin: auto;
    height: var(--shrink-size);
    width: var(--shrink-size);
}

/*.puzzle-layout--original-theme .puzzle-spot--required-visits-3 .puzzle-spot__outer {*/
/*    border: var(--standard-border-width) solid var(--border-color);*/
/*}*/

/*.puzzle-layout--original-theme .puzzle-spot--required-visits-3 .puzzle-spot__middle {*/
/*    margin: auto;*/
/*    height: 80%;*/
/*    width: 80%;*/
/*    border: var(--standard-border-width) solid var(--border-color);*/
/*}*/

/*.puzzle-layout--original-theme .puzzle-spot--required-visits-3 .puzzle-spot__inner {*/
/*    margin: auto;*/
/*    height: 80%;*/
/*    width: 80%;*/
/*    border: var(--standard-border-width) solid var(--border-color);*/
/*}*/

/*.puzzle-layout--original-theme .puzzle-spot--required-visits-1 .puzzle-spot__inner {*/
/*}*/

/*.puzzle-layout--original-theme .puzzle-spot--remaining-visits-2:before {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    !*transform: scale(calc(1 / var(--inner-square-size)));*!*/
/*    !*transform: unset;*!*/
/*    border-radius: var(--border-radius);*/
/*    border: .6vmin solid black;*/
/*}*/


.puzzle-layout--original-theme .puzzle-spot--required-visit-last-move .puzzle-spot__inner {

}

.puzzle-layout--original-theme .puzzle-spot--optional-visit-last-move .puzzle-spot__inner {

}

.puzzle-layout--original-theme .puzzle-spot--contraption .puzzle-spot__inner {
    background-color: var(--contraption-bg-color);
}

.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme.puzzle-layout--user-performed-last-move .puzzle-spot--contraption-visit-last-move .puzzle-spot__inner {
    animation: explode var(--explode-animation-time);
}

.puzzle-layout--original-theme .puzzle-spot--current-location .puzzle-spot__inner {
    background-color: var(--current-location-bg-color) !important;
}

.puzzle-layout--original-theme.puzzle-layout--completed .puzzle-spot {
}

.puzzle-layout--original-theme.puzzle-layout--completed .puzzle-spot--current-location .puzzle-spot__inner {
    background-size: 90%;
    background-image: var(--checkmark-background);
    background-repeat: no-repeat;
    background-position: center;
}

.puzzle-layout--original-theme.puzzle-layout--completed .puzzle-spot__img {
    visibility: hidden;
}

@keyframes z-wiggle {
    from {
        transform: rotateZ(var(--anim-min));
    }
    to {
        transform: rotateZ(var(--anim-max));
    }
}
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme.puzzle-layout--dead-end .puzzle-spot--remaining-visits-1,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme.puzzle-layout--dead-end .puzzle-spot--remaining-visits-2,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme.puzzle-layout--dead-end .puzzle-spot--remaining-visits-3,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme.puzzle-layout--dead-end .puzzle-layout__shadow--remaining-visits-1,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme.puzzle-layout--dead-end .puzzle-layout__shadow--remaining-visits-2,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme.puzzle-layout--dead-end .puzzle-layout__shadow--remaining-visits-3 {
    --anim-min: -8deg;
    --anim-max: 8deg;
    animation: z-wiggle .5s ease-in-out infinite alternate;
}

.puzzle-layout--original-theme.puzzle-layout--dead-end .puzzle-spot--current-location .puzzle-spot__inner {
    background-color: var(--current-location-deadend-bg-color) !important;
}

.puzzle-layout--original-theme.puzzle-layout--dead-end .puzzle-spot--current-location {
    --press-depth: 0;
}

.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme.puzzle-layout--dead-end .puzzle-layout__shadow--current-location,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme.puzzle-layout--dead-end .puzzle-spot--current-location {
    animation: breathing 0.5s infinite;
}

.puzzle-layout--original-theme .puzzle-spot--previous-location {

}

.puzzle-layout--original-theme .puzzle-spot--has-been-destination {

}

.puzzle-layout--original-theme .puzzle-spot--possible-redo-location .puzzle-spot__inner {
    /*--redo-outline-color: green;*/
    /* could be a tiny bit darker in colour - mother */
    outline: var(--redo-outline-width) dashed var(--redo-outline-color);
    outline-offset: var(--redo-outline-offset);
}

.puzzle-layout--original-theme .puzzle-spot--possible-destination .puzzle-spot__inner {
    background-color: var(--possible-destination-bg-color) !important;
}

.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme .puzzle-spot--possible-destination .puzzle-spot__inner {
    animation: inner-glow 1.25s infinite alternate ease-out;
}

/* TODO: This doesn't look great. */
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme .puzzle-layout__shadow--possible-destination.puzzle-layout__shadow--movement-type,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme .puzzle-spot--possible-destination.puzzle-spot--movement-type {
    animation: breathing 2.5s infinite ease-out;
}

.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme .puzzle-layout__shadow--possible-destination.puzzle-layout__shadow--contraption,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--original-theme .puzzle-spot--possible-destination.puzzle-spot--contraption {
    animation: shake 1s infinite linear;
}

.puzzle-layout--original-theme .puzzle-spot--contraption-visit-of-possible-next-move {
    /* TODO: Maybe make this flash slightly*/
}

.puzzle-layout--original-theme .puzzle-spot--required-visit-of-possible-next-move .puzzle-spot__inner {
    background-color: var(--required-possible-move-bg-color) !important;
}

.puzzle-layout--original-theme .puzzle-spot--optional-visit-of-possible-next-move .puzzle-spot__inner {
    background-color: var(--optional-possible-move-bg-color) !important;
}

/* TODO: Needs more oomph */
.user-facing-game--hints-enabled .puzzle-layout--original-theme .puzzle-spot--hinted .puzzle-spot__inner {
    background-color: var(--hint-color) !important;
}

.puzzle-layout--original-theme .puzzle-spot__img--active-mover {

}


/*.puzzle-layout--original-theme .puzzle-spot__img {*/
/*    !*height: 100%;*!*/
/*    width: 90%;*/
/*    opacity: .7;*/
/*}*/

/*.puzzle-layout--original-theme .puzzle-spot__img--slightly-smaller {*/
/*    !*height: 93%;*!*/
/*    !*width: 93%;*!*/
/*    !*padding: 5%;*!*/
/*}*/







