/*
Animate some rotational stuff
https://flaviocopes.com/css-animations/
https://stackoverflow.com/questions/62866915/css-animation-for-hover-rotate
*/

/* via https://csshake.surge.sh/ https://csshake.surge.sh/csshake.css*/
@keyframes shake {
    2% {
        transform: translate(0.5px, -0.5px) rotate(0.5deg); }
    4% {
        transform: translate(2.5px, 1.5px) rotate(1.5deg); }
    6% {
        transform: translate(2.5px, 0.5px) rotate(0.5deg); }
    8% {
        transform: translate(0.5px, -0.5px) rotate(0.5deg); }
    10% {
        transform: translate(-0.5px, -1.5px) rotate(1.5deg); }
    12% {
        transform: translate(-0.5px, 0.5px) rotate(1.5deg); }
    14% {
        transform: translate(-1.5px, 1.5px) rotate(1.5deg); }
    16% {
        transform: translate(2.5px, 1.5px) rotate(1.5deg); }
    18% {
        transform: translate(0.5px, -0.5px) rotate(-0.5deg); }
    20% {
        transform: translate(0.5px, 1.5px) rotate(0.5deg); }
    22% {
        transform: translate(2.5px, -0.5px) rotate(0.5deg); }
    24% {
        transform: translate(-0.5px, 0.5px) rotate(1.5deg); }
    26% {
        transform: translate(-1.5px, 0.5px) rotate(1.5deg); }
    28% {
        transform: translate(0.5px, 0.5px) rotate(-0.5deg); }
    30% {
        transform: translate(1.5px, 1.5px) rotate(1.5deg); }
    32% {
        transform: translate(0.5px, 0.5px) rotate(0.5deg); }
    34% {
        transform: translate(1.5px, -1.5px) rotate(-0.5deg); }
    36% {
        transform: translate(1.5px, 2.5px) rotate(1.5deg); }
    38% {
        transform: translate(-1.5px, 0.5px) rotate(-0.5deg); }
    40% {
        transform: translate(0.5px, -0.5px) rotate(1.5deg); }
    42% {
        transform: translate(2.5px, -1.5px) rotate(-0.5deg); }
    44% {
        transform: translate(-1.5px, -1.5px) rotate(0.5deg); }
    46% {
        transform: translate(1.5px, 0.5px) rotate(0.5deg); }
    48% {
        transform: translate(0.5px, -0.5px) rotate(-0.5deg); }
    50% {
        transform: translate(0.5px, 1.5px) rotate(0.5deg); }
    52% {
        transform: translate(0.5px, -0.5px) rotate(0.5deg); }
    54% {
        transform: translate(2.5px, 0.5px) rotate(1.5deg); }
    56% {
        transform: translate(-1.5px, -1.5px) rotate(0.5deg); }
    58% {
        transform: translate(-1.5px, -0.5px) rotate(-0.5deg); }
    60% {
        transform: translate(0.5px, -1.5px) rotate(0.5deg); }
    62% {
        transform: translate(1.5px, 1.5px) rotate(0.5deg); }
    64% {
        transform: translate(0.5px, 2.5px) rotate(0.5deg); }
    66% {
        transform: translate(0.5px, 0.5px) rotate(1.5deg); }
    68% {
        transform: translate(-1.5px, 2.5px) rotate(0.5deg); }
    70% {
        transform: translate(-1.5px, 1.5px) rotate(1.5deg); }
    72% {
        transform: translate(-0.5px, 2.5px) rotate(1.5deg); }
    74% {
        transform: translate(1.5px, 2.5px) rotate(-0.5deg); }
    76% {
        transform: translate(-0.5px, 0.5px) rotate(1.5deg); }
    78% {
        transform: translate(1.5px, -1.5px) rotate(0.5deg); }
    80% {
        transform: translate(-0.5px, 2.5px) rotate(0.5deg); }
    82% {
        transform: translate(-1.5px, -1.5px) rotate(1.5deg); }
    84% {
        transform: translate(1.5px, 1.5px) rotate(0.5deg); }
    86% {
        transform: translate(0.5px, -0.5px) rotate(-0.5deg); }
    88% {
        transform: translate(-1.5px, 2.5px) rotate(-0.5deg); }
    90% {
        transform: translate(1.5px, 0.5px) rotate(0.5deg); }
    92% {
        transform: translate(0.5px, -1.5px) rotate(0.5deg); }
    94% {
        transform: translate(0.5px, -1.5px) rotate(1.5deg); }
    96% {
        transform: translate(0.5px, 1.5px) rotate(-0.5deg); }
    98% {
        transform: translate(-0.5px, -0.5px) rotate(0.5deg); }
    0%, 100% {
        transform: translate(0, 0) rotate(0); } }

/* From https://codepen.io/hofmannsven/pen/qJyNoa */
@keyframes pulse {
    0% {
        --pulse-scale: 1;
        transform: scale(1, 1);
    }
    25% {
        --pulse-scale: 1;
        transform: scale(1, 1);
    }
    50% {
        --pulse-scale: 1.1;
        transform: scale(1.1, 1.1);
    }
    100% {
        --pulse-scale: 1;
        transform: scale(1, 1);
    }
}


/*TODO: This animation looks bad when we undo it because of the colors it starts in.
   Maybe we shouldn't apply this class after an undo.*/
@keyframes explode {
    0% {
        background-color: var(--start-explosion-color);
    }
    20% {
        background-color: orangered;
    }
    /*60% {*/
    /*    background-color: gray;*/
    /*}*/
}

/*https://stackoverflow.com/questions/34821217/easily-create-an-animated-glow*/
/* TODO: This should be brighter, bigger. */
@keyframes inner-glow {
    from {
        --distance: calc(var(--square-size) * .06);
        box-shadow: 0 0 var(--distance) calc(var(--distance) * -1) var(--glow-color) inset;
    }
    to {
        --distance: calc(var(--square-size) * .06);
        box-shadow: 0 0 var(--distance) var(--distance) var(--glow-color) inset;
    }
}

/*!*https://stackoverflow.com/questions/34821217/easily-create-an-animated-glow*!*/
/*@keyframes outer-glow {*/
/*    from {*/
/*        --glow-color: #aef4af;*/
/*        --distance: calc(var(--square-size) * .12);*/
/*        box-shadow: 0 0 5px -5px var(--glow-color), 0 0 5px -5px var(--glow-color) inset;*/
/*    }*/
/*    to {*/
/*        --glow-color: #80f906;*/
/*        --distance: calc(var(--square-size) * .12);*/
/*        box-shadow: 0 0 5px 5px var(--glow-color), 0 0 5px 5px var(--glow-color) inset;*/
/*    }*/
/*}*/

/*https://stackoverflow.com/questions/34821217/easily-create-an-animated-glow*/
@keyframes outer-glow {
    from {
        box-shadow: 0 0 var(--distance) calc(var(--distance) * -1) var(--glow-color),
        0 0 var(--distance) calc(var(--distance) * -1) var(--glow-color) inset;
    }
    to {
        box-shadow: 0 0 var(--distance) var(--distance) var(--glow-color),
        0 0 var(--distance) var(--distance) var(--glow-color) inset;
    }
}


/* animation: breathing 5s ease-out infinite normal; */
/* https://codepen.io/machi/pen/YymGzP */
@keyframes breathing {
    /* Originally, this was .9, and 1.0 */
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.05);
    }

    60% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

/*!* animation: breathing 5s ease-out infinite normal; *!*/
/*!* https://codepen.io/machi/pen/YymGzP *!*/
/*@keyframes breathing-down-only {*/
/*    !* Originally, this was .9, and 1.0 *!*/
/*    0% {*/
/*        transform: scale(.9);*/
/*    }*/

/*    25% {*/
/*        transform: scale(1.0);*/
/*    }*/

/*    60% {*/
/*        transform: scale(.9);*/
/*    }*/

/*    100% {*/
/*        transform: scale(.9);*/
/*    }*/
/*}*/

@keyframes fade-out {
    from {
        opacity: 1;
    }

    /*90% {*/
    /*    opacity: 0.9;*/
    /*}*/
    to {
        opacity: 0;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    /*90% {*/
    /*    opacity: 0.1;*/
    /*}*/

    to {
        opacity: 1;
    }
}

@keyframes in-from-top {
    from {
        transform: translateY(-103%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes out-to-top {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-103%);
    }
}

@keyframes in-from-left {
    from {
        transform: translateX(-103%);
        opacity: .5;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes in-from-right {
    from {
        transform: translateX(103%);
        opacity: .5;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes out-to-left {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-103%);
        opacity: .5;
    }
}

@keyframes out-to-right {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(103%);
        opacity: .5;
    }
}

@keyframes pop-n-spin {
    0% {
        transform: scale(1) rotate(0deg);
    }

    25% {
        transform: scale(1.25) rotate(180deg);
    }

    50% {
        transform: scale(1.6) rotate(360deg);
    }

    75% {
        transform: scale(1.25) rotate(540deg);
    }

    100% {
        transform: scale(1) rotate(720deg);
    }

}

@keyframes wiggle {
    0% { transform: skewX(9deg); }
    10% { transform: skewX(-8deg); }
    20% { transform: skewX(7deg); }
    30% { transform: skewX(-6deg); }
    40% { transform: skewX(5deg); }
    50% { transform: skewX(-4deg); }
    60% { transform: skewX(3deg); }
    70% { transform: skewX(-2deg); }
    80% { transform: skewX(1deg); }
    90% { transform: skewX(0deg); }
    100% { transform: skewX(0deg); }
}
/* https://css-tricks.com/almanac/properties/t/transform/ */
