
/* Gotta hide the focus rings. */
.carousel__slide--focused,.carousel__slide-focus-ring{
    /*background-color: yellow;*/
    outline: none!important;
}


.world-carousel {
    position: absolute;
    /* Space for hint count bubbles. see .puzzle-selector for the padding*/
    top: -2vh;
    width: 100vw;
    /*height: calc(100% - var(--button-bar-height) - var(--text-overlay-height));*/
    height: 100%;
}

.world-carousel--active {
    opacity: 1;
    /*height: auto;*/
    z-index: 100;
}

.world-carousel--inactive {
    opacity: 0;
    /*height: 0;*/
    z-index: -1;
    /*animation: move 4s linear;*/
    /*transform: translateY(-100vh);*/
}

.user-facing-game--animations-enabled .world-carousel {
    --opacity-transition-duration: 500ms;
    transition: opacity var(--opacity-transition-duration) ease;
}

.world-carousel--dimmed {
    opacity: .4;
}

.user-facing-game--animations-enabled .world-carousel--out-to-top {
    animation: out-to-top 1200ms ease-in, fade-out 1200ms ease-in;
}


.user-facing-game--animations-enabled .world-carousel--in-from-top   {
    animation: in-from-top 1200ms ease-out, fade-in 1200ms ease-out;
    /*animation: in-from-top 1500ms ease-out;*/
}

