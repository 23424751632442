
.buttons-panel {
    position: absolute;
    right: 0;
    left: 0;
    height: var(--button-bar-height);
    z-index: 5;
    /*line-height: 20vw;*/
    /*color: #3a1b48;*/
    /*background-color: #d575ff;*/

    --opacity-transition-duration: 1000ms;
}

.buttons-panel--top {
    top: 0;
}

.buttons-panel--bottom {
    bottom: 0;
}

.buttons-panel__row {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: space-around;
}

.user-facing-game--animations-enabled .buttons-panel__row {
    transition: opacity var(--opacity-transition-duration) ease;
}

/* We can safely animate these since they come into and out of existence as needed. */
.user-facing-game--animations-enabled .buttons-panel__row--active {
    animation: fade-in var(--opacity-transition-duration) ease;
    opacity: 1;
}

.buttons-panel__row--inactive {
    opacity: 0;
}

