/*.options-menu {*/
/*    --space-xxs: .25rem;*/
/*    --space-xs: .5rem;*/
/*    --space-sm: 1rem;*/
/*    --space-md: 1.5rem;*/
/*    --space-lg: 2rem;*/
/*    --space-xl: 3rem;*/
/*    --space-xxl: 6rem;*/

/*}*/

/*.options-menu-outside {*/
/*    position: absolute;*/
/*    top: 0;*/

/*    height: 100%;*/
/*    width: 95%;*/
/*    margin-left: 2.5%;*/
/*    margin-right: 2.5%;*/

/*    display: flex;*/
/*    justify-content: space-around;*/
/*}*/

/*.options-menu {*/
/*    position: absolute;*/
/*    top: 0;*/

/*    height: 100%;*/
/*    max-width: 500px;*/
/*    margin: auto;*/

/*    --bg-color: #D5F5D6;*/
/*    background-color: var(--bg-color);*/

/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-around;*/

/*    font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;*/

/*    border-radius: var(--space-sm);*/
/*    border: black groove 3px;*/
/*}*/

/*.options-menu--active .google-signin-button--enabled,*/
/*.options-menu--active .theme-choice,*/
/*.options-menu--active .options-button,*/
/*.options-menu--active .options-switch {*/
/*    cursor: pointer;*/
/*    pointer-events: auto;*/
/*}*/

/*.options-menu--active {*/
/*    opacity: 1;*/
/*    z-index: 100;*/
/*}*/

/*.options-menu--inactive {*/
/*    opacity: 0;*/
/*    z-index: -1;*/
/*}*/

/*.user-facing-game--animations-enabled .options-menu--fade-out {*/
/*    animation: fade-out 1000ms ease-in;*/
/*}*/

/*.user-facing-game--animations-enabled .options-menu--fade-in {*/
/*    animation: fade-in 1300ms ease-out;*/
/*}*/

/*.options-menu_group {*/
/*    !*margin: 0 auto;*!*/

/*    !*border: 1px solid red;*!*/
/*    height: 100%;*/
/*    width:100%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    !*width: calc(min(95vw, 600px));*!*/
/*}*/

/*.options-menu--active .theme-selector,*/
/*.options-menu--active .options-button,*/
/*.options-menu--active .options-switch {*/
/*    cursor: pointer;*/
/*    pointer-events: auto;*/
/*}*/


.option-setter {
    margin: auto;
    /*height: var(--space-xl);*/
    /*width: var(--space-xl);*/
}

.options-button {
    padding: 10px;
    /*margin: 5px;*/
}

.isolated-mover {
    height: var(--space-xl);
    width: var(--space-xl);
}


.theme-choice--active {
    border-color: #32CE34;
}

.theme-choice--inactive {
    border-color: black;
}

.options-switch {
    vertical-align: middle;
    margin: 5px;
}

/*pwa-auth::part(signInButton) {*/
/*    background-color: green;*/
/*    color: white;*/
/*    transform: rotate3d(0, 0, 1, 25deg);*/
/*}*/

/*.google-signin-button :host([appearance="list"]) .google-btn {*/

/*    background-color: green;*/
/*}*/

/*.google-btn {*/
/*    background-color: green;*/
/*    color: green;*/
/*    !*transform: rotate3d(0, 0, 1, 25deg);*!*/
/*}*/

.theme-choice {
    --size: 8vh;
    --square-size: calc(var(--size) / 2.5);
    --button-height: 2;

    height: var(--size);
    width: var(--size);
    background-color: white;
    border-style: solid;
    border-width: 2px;
    border-radius: 10%;
    /*padding: 5vmin;*/
    display: inline-grid;
    align-content: center;
    justify-content: center;
    place-content: center;
}

/*.menu__section__title {*/
/*    font-size: 2.5vh;*/
/*    font-family: Arial, sans-serif;*/
/*    font-variant: small-caps;*/
/*}*/

/*.theme-selector {*/
/*    display: flex;*/
/*    justify-content: space-around;*/
/*    padding-bottom: 3vmin;*/
/*}*/

/*.options-menu--inactive .theme-selector__option,*/
/*.options-menu--inactive .puzzle-spot {*/
/*    animation-play-state: paused !important;*/
/*}*/

/*.theme-selector__option {*/
/*    --size: 150px;*/
/*    --square-size: calc(var(--size) / 2.5);*/

/*    --button-height: 2;*/

/*    height: var(--size);*/
/*    width: var(--size);*/
/*    background-color: white;*/
/*    border: black solid 7px;*/
/*    border-radius: 5vmin;*/
/*    padding: 5vmin;*/
/*}*/

/*.theme-selector__option--active {*/
/*    border-color: rgb(102, 204, 0);*/
/*    animation: inner-glow 2s infinite alternate;*/
/*}*/

/*.options-label {*/
/*    display: inline-block;*/
/*    !*align-items: center;*!*/
/*}*/

/*.options-text {*/
/*    vertical-align: middle;*/
/*    padding-right: 10px;*/
/*    !*vertical-align: center;*!*/

/*}*/

/*.options-button {*/
/*    padding: 10px;*/
/*    margin: 5px;*/
/*}*/

/*.language-selector {*/

/*}*/

/*.tip-jar {*/

/*}*/

/*.hint-acquisition {*/

/*}*/