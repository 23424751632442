.user-facing-game {
    /* https://stackoverflow.com/questions/12315476/how-to-disable-selection-of-text-on-a-web-page/12315822 */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    /* Only select things are interactable throughout this whole game */
    user-select: none;

    /* pointer-events is set only on specific interactable elements as locally as possible. */
    pointer-events: none;
}

.user-facing-game {
    --button-bar-height: 'set from js';
    --text-overlay-height: 'set from js';
    --game-body-height: 'set from js';

    --theme-major-hue: 'set from js';
    --theme-minor-hue: 'set from js';
    --theme-complement-hue: 'set from js';

    --theme-random-number-1: 'set from js';
    --theme-random-number-2: 'set from js';
    --world-move-transition-time: 'set from js';

    --shadow-offset-x: -1vmin;
    --shadow-offset-y: 2vmin;
    --shadow-radius: 1vmin;
    --standard-shadow: var(--shadow-offset-x) var(--shadow-offset-y) var(--shadow-radius) hsla(0, 0%, 0%, .50);


}

.user-facing-game {
    --hint-color: orange;
    --text-overlay-bg-color: black;
    --text-overlay-text-color: #fff;

    --contraption-bg-color: beige;

    background-color: hsl(var(--theme-major-hue), 40%, 94%);
    /*height: 100%;*/
}

.user-facing-game--animations-enabled.user-facing-game {
    transition: background-color var(--world-move-transition-time) ease;
}

.game-body {
    position: absolute;
    width: 100%;
    height: calc(100% - var(--button-bar-height) - var(--text-overlay-height));
    z-index: 4;
}

.game-body--top {
    /* In this orientation, we leave space for the floating text overlay */
    top: var(--text-overlay-height);
}

.game-body--bottom {
    /* In this orientation, we're only below the button bar */
    top: var(--button-bar-height);
}


.game-body__component-view {
    width: 100vw;
    height: 100%;
    position: relative;

    display: flex;

    flex-direction: column;
    justify-content: space-around;
}

.game-body__component-view .puzzle-layout {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    position: absolute;
}

.game-text-overlay {
    position: absolute;
    z-index: 6;

    width: fit-content;
    min-width: 150px;
    padding-left: 2vw;
    padding-right: 2vw;
    height: calc(var(--text-overlay-height) * .8);

    background-color: var(--text-overlay-bg-color);
    color: var(--text-overlay-text-color);
    opacity: 0.5;

    white-space: nowrap;

    font-size: calc(var(--text-overlay-height) * .55);
    text-align: center;

    /* This is so we can do sneaky things in development with clicking this element. */
    pointer-events: auto;
}

.update-link {
    cursor: pointer;
    color: hsl(120, 60%, 70%);
}

.update-link:hover {
    color: hsl(120, 90%, 80%);
}

.game-text-overlay--bottom-left {
    left: 0;
    bottom: 0;
    border-radius: 0 10px 0 0;
}

.game-text-overlay--bottom-right {
    right: 0;
    bottom: 0;
    border-radius: 10px 0 0 0;
}

.game-text-overlay--top-left {
    left: 0;
    top: 0;
    border-radius: 0 0 10px 0;
}

.game-text-overlay--top-right {
    right: 0;
    top: 0;
    border-radius: 0 0 0 10px;
}
