.static-puzzle {
    --num-cols: 'set from js';
    --num-rows: 'set from js';

    margin: auto;
    position: relative;
    /*max-width: 100%;*/
    /*max-height: 100%;*/

    /*position: absolute;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*margin-right: -50%;*/
    /*transform: translate(-50%, -50%);*/
    /*-webkit-user-drag: none;*/
    /*-khtml-user-drag: none;*/
    /*-moz-user-drag: none;*/
    /*-o-user-drag: none;*/
    /*user-drag: none;*/
}

.static-puzzle__grid {
    --max-squares: calc(max(var(--num-cols), var(--num-rows)));
    --gap-width: 2px;
    --gap-total: calc((var(--max-squares) - 1) * var(--gap-width));
    --size: calc((var(--square-size) - var(--gap-total)) / var(--max-squares)  * .8);
    
    display: grid;
    grid-auto-rows: 1fr;
    /*grid-auto-columns: 1fr;*/
    grid-template-columns: repeat(var(--num-cols), var(--size));
    grid-template-rows: repeat(var(--num-rows), var(--size));
    column-gap: var(--gap-width);
    row-gap: var(--gap-width);
    height: 100%;
    width: 100%;

    /*height: calc(95vw / var(--numCols));*/
}

.static-puzzle__grid__square {
    height: 100%;
    width: 100%;
    /*height: var(--size);*/
    /*width: var(--size);*/
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.vars-from-user-facing-game {
    --theme-major-hue: 'set from js';
    --theme-minor-hue: 'set from js';
    --theme-complement-hue: 'set from js';

    --theme-random-number-1: 'set from js';
    --theme-random-number-2: 'set from js';
    --world-move-transition-time: 'set from js';

    --contraption-bg-color: 'calculated up there';
}

.static-puzzle__grid__square {
    --border-color: black;
    --border-width: 1px;
    --gold-square-bg-color: #ffdf00;
    --empty-square-bg-color: white;
    --gold-square-border-color: rgb(133,117,78);

    --possible-destination-bg-color: hsl(var(--theme-major-hue), 70%, 80%);

}

/*!* This is it! The square miracle! https://stackoverflow.com/a/55713739/6998241 *!*/
/*.static-puzzle__grid::before {*/
/*    content: '';*/
/*    width: 0;*/
/*    padding-bottom: 100%;*/
/*    grid-row: 1 / 1;*/
/*    grid-column: 1 / 1;*/
/*}*/

/*.static-puzzle__grid > *:first-child {*/
/*    grid-row: 1 / 1;*/
/*    grid-column: 1 / 1;*/
/*}*/

.static-puzzle__grid__square {
    border: solid #333 1px;
    position: relative;

    /* For displaying the img inside correctly, if there is one. */
    display: flex;
    align-items: center;
    justify-content: center;
}

.static-puzzle__grid_square--empty {
    background-color: var(--empty-square-bg-color);
}

.static-puzzle__grid__square--not-in-puzzle {
    visibility: hidden;
}

.static-puzzle__grid__square--single-border {
    border-style: solid;
}

.static-puzzle__grid__square--double-border:after {
     content: " ";
     position: absolute;
     z-index: 10;
     top: 1px;
     left: 1px;
     right: 1px;
     bottom: 1px;
     border: var(--border-width) solid var(--border-color);
 }

.static-puzzle__grid__square--triple-border:after {
    content: " ";
    position: absolute;
    z-index: 10;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    border: 3px double var(--border-color);
}

.static-puzzle__grid__square--gold {
    background-color: var(--gold-square-bg-color);
    border-color: var(--gold-square-border-color);
}

/* Make the borders in the tiny underlying puzzles' squares small. */
.puzzle-selector-grid .puzzle-square--static {
    border: var(--border-width) solid var(--border-color);
}

.static-puzzle__grid__square--starting-square {
    background-color: var(--possible-destination-bg-color);
}

.static-puzzle__grid__square--contraption {
    background-color: var(--contraption-bg-color);
}

/*.static-puzzle-spot__img {*/
/*    width: 90%;*/
/*    opacity: .7;*/
/*}*/

.static-puzzle-spot__inner {
    height: 100%;
    width: 100%;
}