
.puzzle-comment-component {
    /* TODO: This is for the green background but it isn't perfect */
    height: 100%;
    width: 100%;
}


.puzzle-comment-component--with-comment {
    background-color: #D5F5D6;
}

.puzzle-comment-component--no-comment {
    background-color: white;
}