.world-builder {

}

.world-change-note {
    font-size: small;
    margin: 2px;
}

.world-change-note--unchanged {
    background-color: limegreen;
}

.world-change-note--changed {
    background-color: red;
}
