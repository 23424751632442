
.vars-from-user-facing-game {
    --theme-major-hue: 'set from js';
    --theme-minor-hue: 'set from js';
    --theme-complement-hue: 'set from js';

    --theme-random-number-1: 'set from js';
    --theme-random-number-2: 'set from js';
    --world-move-transition-time: 'set from js';

    --contraption-bg-color: 'calculated up there';
}

.puzzle-layout--3d-theme .puzzle-spot {
    position: relative;

}
.puzzle-layout--3d-theme .puzzle-spot {
    --standard-border-radius: 50%;

    /*--button-height: 2.0;*/
    --transition-duration: 150ms;

    --left-shadow-unit: calc(var(--square-size) * -.08);
    --down-shadow-unit: calc(var(--square-size) * .18);
    --left-transform: calc(var(--left-shadow-unit) * .5);
    --down-transform: calc(var(--down-shadow-unit) * .5);

    /*--current-location-flat-color: #40d943;*/
    --current-location-flat-color: hsl(111, 77%, 50%) ;
    /*--current-location-side-color:  #32CE34;*/
    --current-location-side-color: hsl(111, 77%, 30%);
    /*--current-location-deadend-flat-color: goldenrod;*/
    --current-location-deadend-flat-color: hsl(var(--theme-minor-hue), 40%, 40%);
    /*--current-location-deadend-side-color: saddlebrown;*/
    --current-location-deadend-side-color: hsl(var(--theme-minor-hue), 40%, 20%);

    /*--done-visits-flat-color: #aaa;*/
    /*--done-visits-side-color: darkgoldenrod;*/
    --done-visits-flat-color: hsl(var(--theme-major-hue), 40%, 30%);
    --done-visits-side-color: hsl(var(--theme-major-hue), 40%, 20%);
    /*--visit-remaining-flat-color: #ddeeee;*/
    --visit-remaining-flat-color: hsl(var(--theme-major-hue), 20%, 85%);
    --visit-remaining-flat-color: hsl(var(--theme-minor-hue), 60%, 90%);
    /*--visit-remaining-side-color: #CFB232;*/
    --visit-remaining-side-color: hsl(var(--theme-minor-hue), 20%, 55%);
    --multiple-visits-remaining-flat-color: var(--visit-remaining-flat-color);
    /*--multiple-visits-remaining-side-color: rgb(255, 217, 102);*/
    --multiple-visits-remaining-side-color: hsl(var(--theme-minor-hue), 20%, 70%);

    /*--possible-destination-flat-color: lightgreen;*/
    --possible-destination-flat-color: hsl(var(--theme-major-hue), 70%, 85%);
    --possible-destination-side-color: hsl(var(--theme-major-hue), 20%, 55%);
    --required-possible-move-flat-color: hsl(var(--theme-major-hue), 40%, 85%);
    --optional-possible-move-flat-color: var(--required-possible-move-flat-color);

    /* Probably going to drop gold squares so these don't matter */
    --gold-flat-color: hsl(52, 79%, 57%);
    --gold-possible-destination-flat-color: #96932C;
    --gold-side-color:hsl(52, 79%, 41%);

    /*--redo-outline-color: var(--current-location-flat-color);*/
    --redo-outline-color: hsla(111, 77%, 70%, .70);
    --redo-outline-width: calc(var(--square-size) * 0.07);
    --redo-outline-offset: calc(var(--square-size) * -0.25);

    /* These should always get overridden */
    --flat_color: red;
    --side-color: red;

}

/* This just helps the img get aligned center */
.puzzle-layout--3d-theme .puzzle-spot__middle,
.puzzle-layout--3d-theme .puzzle-spot__inner {
    border-radius: var(--standard-border-radius);
    height: 100%;
    width: 100%;
}

.puzzle-layout--3d-theme .puzzle-spot__outer {
    content: "";
    position: relative;
    /*top: 0;*/
    /*left: 0;*/
    height: var(--square-size);
    width: var(--square-size);
    border-radius: var(--standard-border-radius);
    background-color: var(--flat_color);

    /*border: solid 1px black ;*/


    transform: translate(calc(var(--button-height) * var(--left-shadow-unit) * -1 + var(--left-transform)),
    calc(var(--button-height) * var(--down-shadow-unit) * -1 + var(--down-transform)));
}

.user-facing-game--animations-enabled .puzzle-layout--3d-theme .puzzle-spot__outer {
    transition: transform var(--transition-duration) ease-in-out;
}

.puzzle-layout--3d-theme .puzzle-spot__outer:before {
    border-radius: var(--standard-border-radius);
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    box-shadow: calc(var(--button-height) * var(--left-shadow-unit) * 1 / 6) calc(var(--button-height) * var(--down-shadow-unit) * 1 / 6) var(--side-color),
    calc(var(--button-height) * var(--left-shadow-unit) * 2 / 6) calc(var(--button-height) * var(--down-shadow-unit) * 2 / 6) var(--side-color),
    calc(var(--button-height) * var(--left-shadow-unit) * 3 / 6) calc(var(--button-height) * var(--down-shadow-unit) * 3 / 6) var(--side-color),
    calc(var(--button-height) * var(--left-shadow-unit) * 4 / 6) calc(var(--button-height) * var(--down-shadow-unit) * 4 / 6) var(--side-color),
    calc(var(--button-height) * var(--left-shadow-unit) * 5 / 6) calc(var(--button-height) * var(--down-shadow-unit) * 5 / 6) var(--side-color),
    calc(var(--button-height) * var(--left-shadow-unit)) calc(var(--button-height) * var(--down-shadow-unit)) var(--side-color);
}

.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--3d-theme .puzzle-spot__outer:before {
    transition: box-shadow ease-in-out var(--transition-duration);
}

.puzzle-layout--3d-theme .puzzle-spot--previous-location.puzzle-spot--remaining-visits-0 .puzzle-spot__outer:before {
    /* No color fade for the box-shadow when we move off a finished spot */
    transition: none;
}

/* TODO: Maybe a sparkle? */
.puzzle-layout--3d-theme .puzzle-spot--gold.puzzle-spot--current-location,
.puzzle-layout--3d-theme .puzzle-spot--gold {
    --button-height: 2;
    --flat_color: var(--gold-flat-color);
}


.puzzle-layout--3d-theme .puzzle-spot--gold.puzzle-spot--possible-destination {
    --flat_color: var(--gold-possible-destination-flat-color);
}

.puzzle-layout--3d-theme .puzzle-spot--gold {
    --side-color: var(--gold-side-color);
}

.puzzle-layout--3d-theme .puzzle-spot--contraption {
}

.puzzle-layout--3d-theme .puzzle-spot--required-visits-1 {
}

.puzzle-layout--3d-theme .puzzle-spot--remaining-visits-0 {
    --button-height: 1;
    --flat_color: var(--done-visits-flat-color);
    --side-color: var(--done-visits-side-color);
}

.puzzle-layout--3d-theme .puzzle-spot--remaining-visits-1 {
    --flat_color: var(--visit-remaining-flat-color);
    --side-color: var(--visit-remaining-side-color);
    --button-height: 2;
}

.puzzle-layout--3d-theme .puzzle-spot--remaining-visits-2 {
    --flat_color: var(--multiple-visits-remaining-flat-color);
    --side-color: var(--multiple-visits-remaining-side-color);
    --button-height: 3;
}

.puzzle-layout--3d-theme .puzzle-spot--remaining-visits-3 {
    --flat_color: var(--multiple-visits-remaining-flat-color);
    --side-color: var(--multiple-visits-remaining-side-color);
    --button-height: 4.0;
}

.puzzle-layout--3d-theme .puzzle-spot--required-visit-last-move {

}

.puzzle-layout--3d-theme .puzzle-spot--optional-visit-last-move {

}

.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--3d-theme.puzzle-layout--user-performed-last-move .puzzle-spot--contraption-visit-last-move .puzzle-spot__outer {
    animation: explode 800ms linear;
}

.puzzle-layout--3d-theme .puzzle-spot--current-location {
    --flat_color: var(--current-location-flat-color);
    --side-color: var(--current-location-side-color);
}

@keyframes button-bounce {
    from {
        --button-height: var(--low-bounce);
    }
    to {
        --button-height: var(--high-bounce);
    }
}

/*@keyframes button-bounce {*/
/*    from {*/
/*        transform: rotateZ(-20deg);*/
/*    }*/
/*    to {*/
/*        transform: rotateZ(20deg);*/
/*    }*/
/*}*/

.puzzle-layout--3d-theme.puzzle-layout--dead-end .puzzle-spot--remaining-visits-1,
.puzzle-layout--3d-theme.puzzle-layout--dead-end .puzzle-spot--remaining-visits-2,
.puzzle-layout--3d-theme.puzzle-layout--dead-end .puzzle-spot--remaining-visits-3 {
    --bounce-diff: .18;
    --low-bounce: calc(2 - var(--bounce-diff));
    --high-bounce: calc(2 + var(--bounce-diff));
}

.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--3d-theme.puzzle-layout--dead-end .puzzle-spot--remaining-visits-1,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--3d-theme.puzzle-layout--dead-end .puzzle-spot--remaining-visits-2,
.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--3d-theme.puzzle-layout--dead-end .puzzle-spot--remaining-visits-3 {
    animation: button-bounce .8s infinite ease-in-out alternate;
}

.puzzle-layout--3d-theme.puzzle-layout--dead-end .puzzle-spot--current-location {
    --flat_color: var(--current-location-deadend-flat-color);
    --side-color: var(--current-location-deadend-side-color);
}

.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--3d-theme.puzzle-layout--dead-end .puzzle-spot--current-location {
    animation: breathing 0.8s infinite;
}

.puzzle-layout--3d-theme .puzzle-spot--previous-location {

}

.puzzle-layout--3d-theme .puzzle-spot--possible-redo-location .puzzle-spot__inner {
    /*outline: var(--redo-outline-width) dashed var(--redo-outline-color);*/
    /*outline-offset: var(--redo-outline-offset);*/
    border: var(--redo-outline-width) var(--redo-outline-color);
    border-style: dashed;

}

.puzzle-layout--3d-theme .puzzle-spot--possible-destination {
    --flat_color: var(--possible-destination-flat-color);
    --side-color: var(--possible-destination-side-color);
}

.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--3d-theme .puzzle-spot--possible-destination.puzzle-spot--movement-type {
    animation: breathing 2.5s infinite ease-out;
}

.user-facing-game--animations-enabled .puzzle-layout--visible.puzzle-layout--3d-theme .puzzle-spot--possible-destination.puzzle-spot--contraption {
    animation: shake 1s infinite linear;
}

.puzzle-layout--3d-theme .puzzle-spot--contraption-visit-of-possible-next-move {

}

.puzzle-layout--3d-theme .puzzle-spot--required-visit-of-possible-next-move {
    --flat_color: var(--required-possible-move-flat-color);
}

.puzzle-layout--3d-theme .puzzle-spot--optional-visit-of-possible-next-move {
    --flat_color: var(--optional-possible-move-flat-color);
}

/* TODO: Needs more oomph */
.user-facing-game--hints-enabled .puzzle-layout--3d-theme .puzzle-spot--hinted {
    --flat_color: var(--hint-color);
}

.puzzle-layout--3d-theme .puzzle-spot__img--active-mover {

}


.puzzle-layout--3d-theme .puzzle-spot__img {
    /*height: 100%;*/
    width: 90%;
    opacity: .7;
    /*filter: drop-shadow(5px 4px 6px) ;*/
}

.puzzle-layout--3d-theme .puzzle-spot__img--slightly-smaller {
    /*height: 93%;*/
    /*width: 93%;*/
    /*padding: 5%;*/
}

.puzzle-layout--3d-theme .puzzle-spot__img--smaller-in-circle {
    /*height: 93%;*/
    /*width: 93%;*/
    padding: 9%;
}






