/* Make sure the quantity of success animations here matches up with Constants.numCompletionAnimations */

.puzzle-layout--completed .puzzle-spot {
    /*will-change: translate, opacity;*/

    /*
    Number between 1 and the total number of visitable locations in the puzzle.
     */
    --order-in-rollout: 'defined in css';
    /*--puzzle-visitable-spots: "available from .puzzle-layout parent'*/
}

/* Hide the shadows when the puzzle completion animation comes online. They look stupid spinning around. */
.user-facing-game--animations-enabled .puzzle-layout--completed .puzzle-layout__shadow {
    transition: box-shadow 1s ease-in;
    box-shadow: none;
}

@keyframes hopping {
    0% {
        transform: translate(calc(var(--depth) * -1vw), calc(var(--depth) * 1vw));
    }
    50% {
        transform: translate(calc(var(--depth) * 1vw), calc(var(--depth) * -1vw));
    }
    100% {
        transform: translate(calc(var(--depth) * -1vw), calc(var(--depth) * 1vw));
    }
}

/* ************************** */
/* puzzle-layout--completed-1 */
/* ************************** */
/* TODO: Looks a little jerky */
.user-facing-game--animations-enabled .puzzle-layout--completed.puzzle-layout--completed-1 .puzzle-spot {
    --visible-cycles: .5;
    --duration: 1.5s;
    /* Diagonals released together */
    --square-order: calc((var(--loc-row) - var(--loc-column) + var(--num-cols) - 1));
    /* Elements move in synchrony with the one to their upper-left */
    --delay: calc(var(--square-order) / max(var(--num-cols), var(--num-rows))
    * var(--duration) * var(--visible-cycles));

    --depth: 1.1;
    animation: hopping var(--duration) infinite ease-in-out;
    animation-delay: var(--delay);
}

@keyframes skew-animation {
    0% {
        transform: skew(0deg, 0deg);
    }
    25% {
        transform: skew(calc(var(--max-skew) * 1deg), calc(var(--max-skew) * 1deg));
    }
    50% {
        transform: skew(0deg, 0deg);
    }
    75% {
        transform: skew(calc(var(--max-skew) * -1deg), calc(var(--max-skew) * -1deg));
    }
    100% {
        transform: skew(0deg, 0deg);
    }
}

/* ************************** */
/* puzzle-layout--completed-2 */
/* ************************** */
.user-facing-game--animations-enabled .puzzle-layout--completed.puzzle-layout--completed-2 .puzzle-spot {
    --visible-cycles: 1;
    --duration: 3s;
    --max-skew: 15;
    /* Diagonals released together */
    --square-order: calc((var(--loc-row) - var(--loc-column) + var(--num-cols) - 1));
    --delay: calc(var(--square-order) / max(var(--num-cols), var(--num-rows)) *
    var(--duration) * var(--visible-cycles));

    animation: skew-animation var(--duration) infinite ease-in-out;
    animation-delay: var(--delay);
}

/*.puzzle-layout--completed.puzzle-layout--completed .puzzle-spot {*/
/*    --press-depth: 0;*/
/*}*/
/*.puzzle-layout--completed.puzzle-layout--completed .puzzle-spot:before {*/
/*    --box-shadow-height: 1.0;*/
/*}*/

/* ************************** */
/* puzzle-layout--completed-3 */
/* ************************** */
/* TODO: Not interesting in original theme */
.user-facing-game--animations-enabled .puzzle-layout--completed.puzzle-layout--completed-3 .puzzle-spot {
    --visible-cycles: 1;
    --duration: 2s;
    --square-order: calc((var(--loc-row) - var(--loc-column) + var(--num-cols) - 1));
    --delay: calc(var(--square-order) / max(var(--num-cols), var(--num-rows)) *
    var(--duration) * var(--visible-cycles));

    animation: shake var(--duration) infinite ease-in, gain-background-checkmark infinite linear;
    animation-delay: var(--delay);
}

@keyframes pop-n-spin-lite {
    0% {
        transform: scale(1) rotate(0);
    }
    25% {
        transform: scale(.8) rotate(calc(var(--turns-per-cycle) / 4 * 1turn));
    }
    50% {
        transform: scale(.6) rotate(calc(var(--turns-per-cycle) / 4 * 2 * 1turn));
    }
    75% {
        transform: scale(.9) rotate(calc(var(--turns-per-cycle) / 4 * 3 * 1turn));
    }
    100% {
        transform: scale(1) rotate(calc(var(--turns-per-cycle) / 4 * 4 * 1turn));
    }
}

/* ************************** */
/* puzzle-layout--completed-4 */
/* ************************** */
.user-facing-game--animations-enabled .puzzle-layout--completed.puzzle-layout--completed-4 .puzzle-spot {
    --visible-cycles: var(--num-rows);
    --turns-per-cycle: 3;
    --duration: 3s;
    animation: pop-n-spin-lite var(--duration) infinite linear;
    /* Rollout bottom left first individually, 1 cycle per row, columns in sync. */
    animation-delay: calc(((var(--num-rows) - 1 - var(--loc-row)) * var(--num-cols) + var(--loc-column)) *
    var(--duration) * var(--visible-cycles) / var(--puzzle-spots));
}

@keyframes filter-hue-rotate {
    from {
        filter: hue-rotate(0);
    }
    to {
        filter: hue-rotate(1turn);
    }
}

/* ************************** */
/* puzzle-layout--completed-5 */
/* ************************** */
/* TODO: Bad timing */
/* TODO: Boring in original theme */
.user-facing-game--animations-enabled .puzzle-layout--completed.puzzle-layout--completed-5 .puzzle-spot {
    --visible-cycles: var(--num-cols);
    --duration: 2s;
    /* Elements move in synchrony with the one to their upper-left */
    --square-order: calc((var(--loc-row) - var(--loc-column) + var(--num-cols) - 1));
    --delay: calc(var(--square-order) / max(var(--num-cols), var(--num-rows)) *
    var(--duration) * var(--visible-cycles));

    animation: filter-hue-rotate var(--duration) infinite linear;
    animation-direction: alternate;
    animation-delay: var(--delay);
}

@keyframes stretchy {
    from {
        filter: blur(0) hue-rotate(0);
    }
    to {
        filter: blur(1vmax) hue-rotate(1turn);
    }
}

/* ************************** */
/* puzzle-layout--completed-6 */
/* ************************** */
.user-facing-game--animations-enabled .puzzle-layout--completed.puzzle-layout--completed-6 .puzzle-spot {
    --visible-cycles: calc(var(--num-cols) * var(--num-cols));
    --duration: 2s;
    animation: stretchy var(--duration) infinite linear;
    animation-direction: alternate;
    /* Rollout bottom left first individually, 1 cycle per row, rows in sync. */
    animation-delay: calc(((var(--num-rows) - 1 - var(--loc-row)) + var(--loc-column) * var(--num-rows)) /
    var(--puzzle-spots) * var(--duration) * var(--visible-cycles));
}


/*.puzzle-layout--completed.puzzle-layout--completed-2 .puzzle-spot {*/
/*    animation: hopping 1.5s infinite ease-in-out;*/
/*    animation-delay: calc((var(--loc-column) + var(--loc-row)) / (var(--num-cols) + var(--num-rows)) * 1.5s);*/
/*}*/

/*@keyframes fly-away {*/
/*    0% {*/
/*        --depth: calc(var(--press-depth));*/
/*        transform: translate(calc(var(--depth) * -1vw), calc(var(--depth) * 1vw)) scale(1);*/
/*        opacity: 1;*/
/*        z-index: 100;*/
/*    }*/

/*    1% {*/
/*        --dx: calc(var(--random-dir-x) * 30px);*/
/*        --dy: calc(var(--random-dir-y) * 30px);*/
/*        !*transform: rotate(calc(var(--loc-row) * 10 + var(--loc-column) * 40)rad) translateX(150vmax) rotate(0);*!*/
/*        transform:  translate(var(--dx),var(--dy)) scale(1);*/
/*        opacity: 1;*/
/*        z-index: 100;*/
/*    }*/

/*    5% {*/
/*        --dx: calc(var(--random-dir-x) * 150px);*/
/*        --dy: calc(var(--random-dir-y) * 150px);*/
/*        !*transform: rotate(calc(var(--loc-row) * 10 + var(--loc-column) * 40)rad) translateX(150vmax) rotate(0);*!*/
/*        transform:  translate(var(--dx),var(--dy)) scale(1);*/
/*        opacity: 1;*/
/*        z-index: 100;*/
/*    }*/

/*    15% {*/
/*        --dx: calc(var(--random-dir-x) * 400px);*/
/*        --dy: calc(var(--random-dir-y) * 400px);*/
/*        !*transform: rotate(calc(var(--loc-row) * 10 + var(--loc-column) * 40)rad) translateX(150vmax) rotate(0);*!*/
/*        transform:  translate(var(--dx),var(--dy)) scale(1);*/
/*        opacity: 1;*/
/*        z-index: 100;*/
/*    }*/

/*    20% {*/
/*        --dx: calc(var(--random-dir-x) * 600px);*/
/*        --dy: calc(var(--random-dir-y) * 600px);*/
/*        !*transform: rotate(calc(var(--loc-row) * 10 + var(--loc-column) * 40)rad) translateX(150vmax) rotate(0);*!*/
/*        transform:  translate(var(--dx),var(--dy)) scale(1);*/
/*        opacity: 1;*/
/*        z-index: 100;*/
/*    }*/

/*    30% {*/
/*        --dx: calc(var(--random-dir-x) * 800px);*/
/*        --dy: calc(var(--random-dir-y) * 800px);*/
/*        !*transform: rotate(calc(var(--loc-row) * 10 + var(--loc-column) * 40)rad) translateX(150vmax) rotate(0);*!*/
/*        transform:  translate(var(--dx),var(--dy)) scale(1);*/
/*        opacity: 1;*/
/*        z-index: 100;*/
/*    }*/

/*    31% {*/
/*        --dx: calc(var(--random-dir-x) * 800px);*/
/*        --dy: calc(var(--random-dir-y) * 800px);*/
/*        !*transform: rotate(calc(var(--loc-row) * 10 + var(--loc-column) * 40)rad) translateX(150vmax) rotate(0);*!*/
/*        transform:  translate(var(--dx),var(--dy)) scale(1);*/
/*        opacity: 0;*/
/*        z-index: -1;*/
/*    }*/

/*    32% {*/
/*        --depth: calc(var(--press-depth));*/
/*        transform: translate(calc(var(--depth) * -1vw), calc(var(--depth) * 1vw)) scale(0);*/
/*        opacity: 0;*/
/*        z-index: -1;*/
/*    }*/
/*    70% {*/
/*        --depth: calc(var(--press-depth));*/
/*        transform: translate(calc(var(--depth) * -1vw), calc(var(--depth) * 1vw)) scale(1);*/
/*        opacity: 1;*/
/*        z-index: -1;*/
/*    }*/

/*    100% {*/
/*        --depth: calc(var(--press-depth));*/
/*        transform: translate(calc(var(--depth) * -1vw), calc(var(--depth) * 1vw)) scale(1);*/
/*        opacity: 1;*/
/*        z-index: -1;*/
/*    }*/
/*}*/
/*.puzzle-layout--completed .puzzle-spot {*/
/*    !*--visible-cycles: calc(var(--puzzle-spots));*!*/
/*    --visible-cycles: 1;*/
/*    --duration: calc(var(--num-cols) * var(--num-rows) * 1s);*/
/*    animation: fly-away var(--duration) infinite ease-in;*/
/*    !* Rollout bottom left first individually, 1 cycle per row, columns in sync. *!*/
/*    animation-delay: calc(((var(--num-rows) - 1 - var(--loc-row)) * var(--num-cols) + var(--loc-column)) /*/
/*    var(--puzzle-spots) * var(--duration) * var(--visible-cycles));*/
/*}*/


/*@keyframes color-invert {*/
/*    0% {*/
/*        filter: invert(0);*/
/*    }*/
/*    50% {*/
/*        filter: invert(1);*/
/*    }*/
/*    100% {*/
/*        filter: invert(0);*/
/*    }*/
/*}*/

/*.puzzle-layout--completed.puzzle-layout--completed-5 .puzzle-spot {*/
/*    --visible-cycles: var(--num-cols);*/
/*    --turns-per-cycle: 5;*/
/*    --duration: 2s;*/
/*    animation: color-invert var(--duration) infinite linear;*/
/*    !* Rollout bottom left first individually, 1 cycle per row, rows in sync. *!*/
/*    animation-delay: calc(((var(--num-rows) - 1 - var(--loc-row)) + var(--loc-column) * var(--num-rows)) /*/
/*    var(--puzzle-spots) * var(--duration) * var(--visible-cycles));*/
/*}*/

