
.search-controls__big-query-box {

}

.search-controls-panel--grid {
    display: grid;
    /*font-size: 11px;*/
    /*width: 373px;*/
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    /*justify-content: space-between;*/
}

.search-controls__label {
    font-size: 11px;
}

.search-controls__checkboxes {

}

.search-controls__progress-bar>svg {
    position: absolute;
    right: 0;
    bottom:0;
    margin: 10px;
}

.search-controls__progress-button {

}