/* TODO: http://getbem.com/naming/ Good conventions! */

/* Make sure our animations are paused when off screen */
.world-carousel--inactive .puzzle-selector__elem__inner {
    animation-play-state: paused !important;
}

.puzzle-selector {
    /*display: inline-block;*/

    /*position: absolute;*/
    /*top: 0;*/
    width: 100vw;
    height: var(--game-body-height);
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    /* Room for shadows */
    padding-bottom: 80px;
    /* Equilibrated in .world-carousel */
    padding-top: 2vh;
}

/* Stuff with css variables we can use. */
.puzzle-selector--variables {
    --num-rows: 'set from js';
    --num-cols: 'set from js';
    --row-gap: 'set from js';
    --col-gap: 'set from js';
    --square-size: 'set from js';

    --theme-random-number-1: 'set from js';
    --theme-random-number-2: 'set from js';

    --theme-major-hue: 'set from js';
    --theme-minor-hue: 'set from js';
    --theme-complement-hue: 'set from js';

}

/* All our color definitions for puzzle-selector components */
.puzzle-selector {
    /* For Lab only */
    --not-enabled-bg-color: lightpink;

    /* Custom Colors */
    --previously-solved-bg-color: hsl(var(--theme-major-hue), 80%, 70%);
    --sufficient-progress-bg-color: hsl(var(--theme-minor-hue), 80%, 93%);
    --insufficient-progress-bg-color: hsl(var(--theme-minor-hue), 80%, 93%);

    --glow-color: hsl(var(--theme-minor-hue), 80%, 60%);
    --previously-unplayed-border-color: hsl(var(--theme-minor-hue), 80%, 60%);
    --previously-played-border-color: hsl(var(--theme-major-hue), 50%, 40%);
    --number-border-color: hsl(var(--theme-major-hue), 50%, 20%);
    --previously-solved-border-color: var(--previously-played-border-color);

    /* Custom Sizes */
    --border-width: calc(var(--square-size) * .04);
    --font-size: calc(var(--square-size) * .25);
}

.puzzle-selector-grid {
    /* We have 3 of these and need them to stack on top of each other. */
    position: absolute;
    display: grid;
    grid-template-columns: repeat(var(--num-cols), var(--square-size));
    grid-template-rows: repeat(var(--num-rows), var(--square-size));
    column-gap: var(--col-gap);
    row-gap: var(--row-gap);
}

.puzzle-selector__elem {
    --col-num: 'set from js';
    --row-num: 'set from js';

    position: relative;
    width: calc(var(--square-size));
    height: calc(var(--square-size));

    border-radius: 10%;

    z-index: calc(var(--row-num) - var(--col-num) + 50);
}

.world-carousel--active .puzzle-selector__elem--sufficient-progress {
    pointer-events: auto;
    cursor: pointer;
}

.puzzle-selector__elem--layer-1-filler {
    background-color: rgba(0,0,0,0);
}

.puzzle-selector__elem--layer-2-shadow {
    width: calc(var(--square-size));
    height: calc(var(--square-size));

    background-color: rgba(0,0,0,0);

    border-radius: 10%;

    /* This could be a basic box-shadow instead, like I give a shit; it looks fine. */
    /*--shadow-offset-x: calc(var(--square-size) * -.08);*/
    /*--shadow-offset-y: calc(var(--square-size) * .15);*/
    /*--shadow-radius: calc(var(--square-size) * .12);*/
    /*box-shadow: var(--shadow-offset-x) var(--shadow-offset-y) var(--shadow-radius) hsla(0, 0%, 0%, .75);*/
    /*filter: drop-shadow(var(--offset-x) var(--offset-y) var(--radius) hsla(0, 0%, 0%, .7));*/
    box-shadow: var(--standard-shadow);

}

.puzzle-selector__elem--layer-2-filler {

}

.puzzle-selector__elem--layer-3-filler {

}

.puzzle-selector__elem__inner {
    /* Aligns the content of the grid elements in the center */
    display: flex;
    height: 96%;
    width: 96%;
    top: 2%;
    right: 2%;
    left: 2%;
    bottom: 2%;
    position: relative;
    border-radius: 10%;
    pointer-events: none;
    /*align-items: center;*/
    /*top: 0; left:0; right:0; bottom: 0;*/
}


.puzzle-selector__number__inner {
    font-size: var(--font-size);
    padding-bottom: 5%;
}

.puzzle-selector__number {
    margin: 0;
    position: absolute;
    height: 40%;
    width: 40%;
    background-color: var(--hint-color);
    border-radius: 50%;
    border: solid var(--number-border-color) var(--border-width);
    top: -15%;
    right: -15%;
    z-index: 100;

    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
}

.user-facing-game--hints-disabled .puzzle-selector__number {
    display: none;
}


/*.puzzle-selector__number.puzzle-selector__number_inner {*/
/*    display: inline-block;*/
/*    vertical-align: middle;*/
/*    line-height: normal;*/
/*}*/

.puzzle-selector__elem--sufficient-progress {
    background-color: var(--sufficient-progress-bg-color);
}

.puzzle-selector__elem--insufficient-progress {
    background-color: var(--insufficient-progress-bg-color);
    /*border: solid var(--insufficient-progress-border-color) var(--border-width);*/
    opacity: .35;
    box-shadow: none;
}

.puzzle-selector__elem--previously-played {
    border: solid var(--previously-played-border-color) var(--border-width);
}

.puzzle-selector__elem--previously-unplayed {
    /*animation: glow 1s infinite alternate ease-out;*/
    border: solid var(--previously-unplayed-border-color) var(--border-width);
}

.puzzle-selector__elem--previously-solved {
    /*-webkit-box-shadow: inset 0 .2vw 0 #E3BE46;*/
    /*-moz-box-shadow: inset 0 .2vw 0 #E3BE46;*/

    /*border: calc(min(.6vw, 4)) solid #B69119;*/
    /*background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);*/
    background-color: var(--previously-solved-bg-color);
    border: solid var(--previously-solved-border-color) var(--border-width);
}

.puzzle-selector__elem--not-enabled {
    background-color: var(--not-enabled-bg-color);
}

.user-facing-game--animations-enabled .puzzle-selector__elem--last-loaded-puzzle.puzzle-selector__elem {
    /* --glow-color set above */
    animation: inner-glow 1.6s infinite alternate ease;
}

.user-facing-game--animations-disabled .puzzle-selector__elem--last-loaded-puzzle .puzzle-selector__elem__inner {
    --distance: calc(var(--square-size) * .06);
    /* TODO: This can now be on the .puzzle-selector element since we're doing shadows on a different layer. */
    box-shadow: 0 0 var(--distance) var(--distance) var(--glow-color),
    0 0 var(--distance) var(--distance) var(--glow-color) inset;
}

