
.variables {
    --square-size: 'set from js';
    --num-cols: 'set from js';
    --num-rows: 'set from js';
    --puzzle-spots: 'set from js';
    --row-gap: 'set from js';
    --col-gap: 'set from js';
}

.solution-path {
    --width-gap: calc(var(--col-gap) * (var(--num-cols) - 1));
    --height-gap: calc(var(--row-gap) * (var(--num-rows) - 1));
    --width-squares: calc(var(--num-cols) * var(--square-size));
    --height-squares: calc(var(--num-rows) * var(--square-size));

    /*margin: 0 auto;*/
    /*width: calc(var(--num-cols) * (var(--square-size) + var(--grid-gap)) - var(--grid-gap));*/

    z-index: 169;
    position: absolute;
    /*top: 0;*/
    left: auto;
    right: auto;

    width: calc(var(--width-gap) + var(--width-squares));
    height: calc(var(--height-gap) + var(--height-squares));

    /*width: 505px;*/
    /*height: 536px;*/
    pointer-events: none;
}

.solution-path__origin {
    fill: red;
    opacity: .6;
    stroke-width: 8px;
    /*fill-opacity: 0%;*/
}

.solution-path__move {
    position: absolute;
    top: 0;
    stroke-width: 8px;
    stroke-linecap: round;
    opacity: .6;

    /* Default color to see easily which we want to customize */
    stroke: deeppink;
}

.solution-path__contraption-visit {
    fill: orange;
    stroke: red;
    stroke-width: 0px;
    opacity: .6;
}

.solution-path__move--mt-manhattan {
    stroke: red;
}

.solution-path__move--mt-manhattan-skate {
    stroke: blue;
}

.solution-path__move--mt-diagonal,
.solution-path__move--mt-diagonal-skip,
.solution-path__move--mt-diagonal-jump,
.solution-path__move--mt-diagonal-skate,
.solution-path__move--mt-diagonal-bullet {
    stroke: blue;
}

.solution-path__move--mt-manhattan-skip {
    stroke: saddlebrown;
}

.solution-path__move--mt-cannon,
.solution-path__move--mt-free-cannon {
    stroke: lightsalmon;
}

.solution-path__move--mt-manhattan-bullet,
.solution-path__move--mt-diagonal-bullet {
    stroke: lightseagreen;
}

.solution-path__move--mt-knight, .solution-path__move--mt-chinese-horse, .solution-path__move--mt-chinese-diagonal-horse, .solution-path__move--mt-chinese-optional-horse, .solution-path__move--mt-chinese-optional-diagonal-horse, .solution-path__move--mt-legit-horse {
    stroke: limegreen;
}

.solution-path__move--mt-liege, .solution-path__move--mt-liege-skate {
    stroke: purple;
}