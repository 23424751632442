.popup-screen {
    --space-xxs: .25rem;
    --space-xs: .5rem;
    --space-sm: 1rem;
    --space-md: 1.5rem;
    --space-lg: 2rem;
    --space-xl: 3rem;
    --space-xxl: 6rem;

}


.popup-screen-outer {
    position: absolute;
    top: 0;

    height: 100%;
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;

    display: flex;
    justify-content: space-around;
}

.popup-screen {
    /*position: absolute;*/
    /*top: 0;*/

    height: 100%;
    /*min-width: 95vmin;*/
    /*max-width: 500px;*/
    width: 90vmin;
    margin: auto;

    --bg-color: #D5F5D6;
    background-color: var(--bg-color);

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;

    border-radius: var(--space-sm);
    border: solid black 3px;
}

.user-facing-game--animations-enabled .popup-screen {
    --opacity-transition-duration: 500ms;
    transition: opacity var(--opacity-transition-duration) ease;
}

.popup-screen--active .google-signin-button--enabled,
.popup-screen--active .theme-choice,
.popup-screen--active .options-button,
.popup-screen--active .options-switch {
    cursor: pointer;
    pointer-events: auto;
}

.menu__section {
    --option-count: 'set by js';
    min-height: 12%;

    /*border: red solid 1px;*/
    text-align: center;

    display: grid;
    grid-template-columns: 1fr repeat(var(--option-count), .25fr);
    gap: var(--space-sm);
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
    padding-left: var(--space-sm);
    padding-right: var(--space-sm);

    /*border: solid pink 1px;*/
}

.option-explanation {
    margin: auto;
    /*border: solid pink 1px;*/
}

.option-explanation .option-heading {
    margin: 0;
    font-weight: 500;
    line-height: 20px;
    font-size: 3vh;
    padding-bottom: 1vh;
}

.option-explanation .explanation-text {
    margin: 0;
    font-weight: 400;
    font-size: 2.5vh;
}

.option-explanation .email-text {
    margin-top: 5px;
    font-weight: 500;
    font-family: monospace;
    font-size: 2.1vh;
}

.instruction-group {
    min-height: 10%;
    margin-left: var(--space-sm);
    margin-right: var(--space-sm);
    /*border: solid pink 1px;*/
}

.instruction-heading {
    font-size: 3vh;
    font-weight: 500;
}

.instruction-text {
    font-size: 2vh;
}

.popup-screen--active {
    opacity: 1;
    z-index: 100;
}

.popup-screen--inactive {
    opacity: 0;
    z-index: -1;
}
