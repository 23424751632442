/* The good easy svg editor: https://mediamodifier.com/design# */

.puzzle-spot__inner--visitable-mt-manhattan,
.static-puzzle-spot__inner--visitable-mt-manhattan {
    background-image: url("../img/manhattan-mover-1.svg");
}

.puzzle-spot__inner--visitable-mt-diagonal,
.static-puzzle-spot__inner--visitable-mt-diagonal {
    background-image: url("../img/diagonal-mover-1.svg");
}

.puzzle-spot__inner--visitable-mt-liege,
.static-puzzle-spot__inner--visitable-mt-liege {
    background-image: url("../img/crown-king-1.svg");
}

.puzzle-spot__inner--visitable-mt-liege-skate,
.static-puzzle-spot__inner--visitable-mt-liege-skate {
    background-image: url("../img/luge.svg");
}

.puzzle-spot__inner--visitable-mt-manhattan-skate,
.static-puzzle-spot__inner--visitable-mt-manhattan-skate {
    background-image: url("../img/skate-5.svg");
}

.puzzle-spot__inner--visitable-mt-liege-bullet,
.static-puzzle-spot__inner--visitable-mt-liege-bullet {
    background-image: url("../img/crown-queen-1.svg");
}

.puzzle-spot__inner--visitable-mt-diagonal-skate,
.static-puzzle-spot__inner--visitable-mt-diagonal-skate {
    background-image: url("../img/skate-1.svg");
}

.puzzle-spot__inner--visitable-mt-cannon,
.static-puzzle-spot__inner--visitable-mt-cannon {
    background-image: url("../img/cannon-1.svg");
}

.puzzle-spot__inner--visitable-mt-free-cannon,
.static-puzzle-spot__inner--visitable-mt-free-cannon {
    background-image: url("../img/cannon-2.svg");
}

.puzzle-spot__inner--visitable-mt-manhattan-bullet,
.static-puzzle-spot__inner--visitable-mt-manhattan-bullet {
    background-image: url("../img/bullet-manhattan-1-flipped.svg");
}

.puzzle-spot__inner--visitable-mt-diagonal-bullet,
.static-puzzle-spot__inner--visitable-mt-diagonal-bullet {
    background-image: url("../img/bullet-diagonal-1.svg");
}

.puzzle-spot__inner--visitable-mt-manhattan-jump,
.static-puzzle-spot__inner--visitable-mt-manhattan-jump {
    background-image: url("../img/jumper.svg");
}

.puzzle-spot__inner--visitable-mt-manhattan-drag,
.static-puzzle-spot__inner--visitable-mt-manhattan-drag {
    background-image: url("../img/bike-1.svg");
}

.puzzle-spot__inner--visitable-mt-manhattan-skip,
.static-puzzle-spot__inner--visitable-mt-manhattan-skip {
    background-image: url("../img/kangaroo-1.svg");
}

.puzzle-spot__inner--visitable-mt-diagonal-jump,
.static-puzzle-spot__inner--visitable-mt-diagonal-jump {
    background-image: url("../img/hang-glider.svg");
}

.puzzle-spot__inner--visitable-mt-diagonal-drag,
.static-puzzle-spot__inner--visitable-mt-diagonal-drag {
    background-image: url("../img/kayak.svg");
}

.puzzle-spot__inner--visitable-mt-diagonal-skip,
.static-puzzle-spot__inner--visitable-mt-diagonal-skip {
    background-image: url("../img/pogo.svg");
}

/* HORSES */
.puzzle-spot__inner--visitable-mt-knight,
.static-puzzle-spot__inner--visitable-mt-knight {
    background-image: url("../img/knight-2.svg");
}

.puzzle-spot__inner--visitable-mt-legit-horse,
.static-puzzle-spot__inner--visitable-mt-legit-horse {
    background-image: url("../img/horse-oracle-rotated.svg");
}

.puzzle-spot__inner--visitable-mt-chinese-horse,
.static-puzzle-spot__inner--visitable-mt-chinese-horse {
    background-image: url("../img/horse-realistic-1.svg");
}

.puzzle-spot__inner--visitable-mt-chinese-diagonal-horse,
.static-puzzle-spot__inner--visitable-mt-chinese-diagonal-horse {
    background-image: url("../img/horse-realistic-3.svg");
}

.puzzle-spot__inner--visitable-mt-chinese-optional-horse,
.static-puzzle-spot__inner--visitable-mt-chinese-optional-horse {
    background-image: url("../img/horse-realistic-4.png");
}

.puzzle-spot__inner--visitable-mt-chinese-optional-diagonal-horse,
.static-puzzle-spot__inner--visitable-mt-chinese-optional-diagonal-horse {
    background-image: url("../img/horse-realistic-2.svg");
}

/* WYES */
.puzzle-spot__inner--visitable-mt-r-wye,
.static-puzzle-spot__inner--visitable-mt-r-wye {
    background-image: url("../img/why-r.png");
}

.puzzle-spot__inner--visitable-mt-t-wye,
.static-puzzle-spot__inner--visitable-mt-t-wye {
    background-image: url("../img/why-t.png");
}

.puzzle-spot__inner--visitable-mt-y-wye,
.static-puzzle-spot__inner--visitable-mt-y-wye {
    background-image: url("../img/why-y.png");
}

.puzzle-spot__inner--visitable-mt-h-wye,
.static-puzzle-spot__inner--visitable-mt-h-wye {
    background-image: url("../img/why-h.png");
}

.puzzle-spot__inner--visitable-mt-n-wye,
.static-puzzle-spot__inner--visitable-mt-n-wye {
    background-image: url("../img/why-n.png");
}

.puzzle-spot__inner--visitable-mt-b-wye,
.static-puzzle-spot__inner--visitable-mt-b-wye {
    background-image: url("../img/why-b.png");
}

.puzzle-spot__inner--visitable-mt-v-wye,
.static-puzzle-spot__inner--visitable-mt-v-wye {
    background-image: url("../img/why-v.png");
}

.puzzle-spot__inner--visitable-mt-f-wye,
.static-puzzle-spot__inner--visitable-mt-f-wye {
    background-image: url("../img/why-f.png");
}

/* BOMBS */
.puzzle-spot__inner--visitable-contraption-queen-bomb,
.static-puzzle-spot__inner--visitable-contraption-queen-bomb {
    background-image: url("../img/bomb-alldirections.svg");
}

.puzzle-spot__inner--visitable-contraption-manhattan-bomb,
.static-puzzle-spot__inner--visitable-contraption-manhattan-bomb {
    background-image: url("../img/bomb-manhattan-1.svg");
}

.puzzle-spot__inner--visitable-contraption-horizontal-bomb,
.static-puzzle-spot__inner--visitable-contraption-horizontal-bomb {
    background-image: url("../img/bomb-manhattan-2.svg");
}

.puzzle-spot__inner--visitable-contraption-vertical-bomb,
.static-puzzle-spot__inner--visitable-contraption-vertical-bomb {
    background-image: url("../img/bomb-vertical-2.svg");
}

.puzzle-spot__inner--visitable-contraption-diagonal-bomb,
.static-puzzle-spot__inner--visitable-contraption-diagonal-bomb {
    background-image: url("../img/bomb-diagonal-1.svg");
}


.puzzle-spot__inner--background-container,
.static-puzzle-spot__inner--background-container {
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
}

.puzzle-spot__inner--background-slightly-smaller,
.static-puzzle-spot__inner--background-slightly-smaller {

}

.puzzle-layout--3d-theme .puzzle-spot__inner--background-smaller-in-circle {
    background-size: 70%;
}