/* The good easy svg editor: https://mediamodifier.com/design# */
.nice-button {
    height: var(--button-size);
    width: var(--button-size);
    margin-top: calc(var(--button-size) * .1);
    font-size: 0;
}

/* Vars we use below to set the styles */
.nice-button {
    --button-size: calc(var(--button-bar-height) * 11 * 0.0666);
    --standard-border-width: calc(var(--button-size) * .03);

    --drop-shadow-color: #aaa;
    --shadow-transparency: 0.12;
    --button-background-color: mintcream;
    --border-color: #555;

    --button-press-animation-duration: 60ms;


    /*box-shadow: 0px 10px 4px var(--drop-shadow-color);*/
    box-shadow: var(--standard-shadow);
}

.nice-button.nice-button--small {
    --button-size: calc(var(--button-bar-height) * 11 * 0.0666 * 0.7);
}

.user-facing-game--animations-enabled .nice-button {
    /* Inherited from .buttons-panel */
    transition: opacity var(--opacity-transition-duration) ease;
    /* https://stackoverflow.com/questions/21003535/anyway-to-prevent-the-blue-highlighting-of-elements-in-chrome-when-clicking-quic */
}

.user-facing-game--animations-enabled .nice-button__inner {
    transition: transform var(--button-press-animation-duration) ease-in-out;
}

.nice-button__inner {
    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: var(--button-background-color);
    /*background-color: #cccccc;*/

    border: var(--standard-border-width) solid var(--border-color);
}

/* TODO: The content can sometimes not fit this. Gotta shrink the font for big numbers somehow. */
.nice-button__number-container {
    margin: 0;
    font-size: calc(var(--button-size) * .167);
    text-align: center;
    position: absolute;
    height: calc(var(--button-size) * .3);
    width: calc(var(--button-size) * .3);
    background-color: white;
    border-radius: 50%;
    border: solid var(--border-color) var(--standard-border-width);
    top: calc(var(--button-size) * -.073);
    right: calc(var(--button-size) * -.073);
    z-index: 10;

}

/* Make the number glow pleasantly as the noise indicating you've used up a precious hint plays. */
.user-facing-game--animations-enabled .nice-button--highlighted .nice-button__inner {
    --glow-color: var(--hint-color);
    --distance: calc(var(--button-size) * .12);
    opacity: 1.0;
    animation: outer-glow 1s ease alternate;
    animation-iteration-count: 2;
}

/* TODO: Maybe show something here for "just got a hint" */
.user-facing-game--animations-disabled .nice-button--highlighted .nice-button__inner {
    --glow-color: var(--hint-color);

}

.nice-button--pressed .nice-button__inner {
    --distance-to-shadow-proportion: 0.4;
    transform: translate(calc(var(--shadow-offset-x) * var(--distance-to-shadow-proportion)), calc(var(--shadow-offset-y) * var(--distance-to-shadow-proportion)));
}

.nice-button__img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;

    opacity: .8;
}

.nice-button--active .nice-button__inner {
    opacity: 1;
}

.nice-button--inactive {
}

.nice-button--inactive .nice-button__inner {
    opacity: .3;
}

/*.nice-button--visible {*/
/*    visibility: visible;*/
/*}*/

.nice-button--visible.nice-button--active {
    cursor: pointer;
    pointer-events: auto;
}

.nice-button--hidden {
    /*visibility: hidden;*/
    opacity: 0;
}

.nice-button--squarelike,
.nice-button--squarelike .nice-button__inner {
    border-radius: 10%;
}

.nice-button--circlelike,
.nice-button--circlelike .nice-button__inner {
    border-radius: 50%;
}

.user-facing-game--animations-enabled .nice-button--encouraged {
    animation: pulse 1.5s infinite;
}

.user-facing-game--animations-disabled .nice-button--encouraged {
    background-color: var(--hint-color);
}

#UndoButton .nice-button__img {
    height: 95%;
    width: 95%;
}

#AllocateHintButton .nice-button__img {
    height: 75%;
    width: 75%;
}

#QuackButton .nice-button__img {
    height: 90%;
    width: 90%;
}

#ReturnButton .nice-button__img {
    height: 100%;
    width: 100%;
}

#WorldButton .nice-button__img {
    height: 95%;
    width: 95%;
}

#RestartButton .nice-button__img {
    height: 90%;
    width: 90%;
}

#NextPuzzleButton .nice-button__img {
    height: 70%;
    width: 70%;
}

#WorldLeftButton .nice-button__img {
    height: 75%;
    width: 75%;
}

#InstructionsButton .nice-button__img {
    height: 95%;
    width: 95%;
}

#WorldRightButton .nice-button__img {
    height: 75%;
    width: 75%;
}

#OptionsButton .nice-button__img {
    height: 80%;
    width: 80%;
}
