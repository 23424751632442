/* Good site for fonts: https://fonts.google.com/ */
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@900&family=Major+Mono+Display&family=Roboto+Mono:wght@700&display=swap');

.puzzle-lab__text-area {
    width: 90%;
    /*height: 60%;*/
    /*padding: 15px 22px;*/
    border: 4px solid #ccc;
    border-radius: 5px;
    background-color: aliceblue;
    resize: none;
}

.puzzle-lab__text-area--same-as-puzzle {
    border: 4px solid #E3BE46;
}

.puzzle-lab__text-area--different-from-puzzle {

}

.puzzle-lab__text-area--puzzle-text {
    font-family: 'Roboto Mono', monospace;
    font-stretch: ultra-expanded;
}

.info-text {
    font-size: 15px;
    margin: 2px;
}

.info-text--small {
    font-size: small;
}

.info-text--clickable:hover {
    cursor: pointer;
    color: red;
}

.info-text--beside-checkbox {
    margin-left: 25px;
}

.puzzle-lab__checkbox {

}


.puzzle-lab__checkbox--floating-label {
    float: left;
    /*margin-top: 5px;*/
}

.text-box {

}


.text-box--tiny {
    width: 20px;
}

.text-box--small {
    width: 50px;
}

.text-box--medium {
    width: 100px;
}

.puzzle-lab__button {

}

.level-list {
    width: 100%;
}


.level-list__level {

}

.level-list__level--active {
    /*font-style: italic;*/
    font-weight: bold;
    /*background-color: beige;*/
}

.level-list__level--enabled {
}

.level-list__level--disabled {
    font-style: oblique;
}

.level-list__level--no-comment {

}

.level-list__level--definition-has-no-comment {

}

.level-list__level--has-comment {
    background-color: #D5F5D6;
}

.level-list__level--definition-has-comment {
    background-color: lightpink;
}

.level-list__level--important-comment {
    background-color: crimson;
}

.puzzle-selector__elem--highlighted {
    border-color: dodgerblue;
    border-width: 5px;
}
.puzzle-selector--fixed-size {
    padding: 0;
    /*width: var(--fixed-width);*/
}

.lab-active-puzzle-panel .puzzle-layout {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

/***************/
/* Panel Stuff */
/***************/
.puzzle-lab__layout {
    display: grid;
    max-height: 1050px;
    grid-template-rows: repeat(12, 1fr);
    max-width: 1850px;
    grid-template-columns: 240px 340px repeat(2, 1fr) 450px;
    /*row-gap: 2px;*/
    /*column-gap: 2px;*/
}

/* Naming required by GridLayout component */
.panel {
    display: inline-block;
    border: double thick;
    padding: 2px;
}

.puzzle-definition-controls-panel {
    grid-row: 1 / span 3;
    grid-column: 1 / span 1;
}

.move-controls-panel {
    grid-row: 4 / span 1;
    grid-column: 1;
    text-align: center;
}

.lab-active-puzzle-panel {
    grid-row: 5 / span 8;
    grid-column: 1 / span 2;

    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.search-controls-panel {
    grid-row: 1 / span 4;
    grid-column: 2 / span 1;
}

.solution-describer-panel {
    grid-row: 1 / span 4;
    grid-column: 3 / span 1;
}

.search-results-panel {
    grid-row: 5 / span 8;
    grid-column: 3 / span 1;
}

.puzzle-comment-panel {
    grid-row: 1 / span 2;
    grid-column: 4 / span 1;
}

.world-selector-panel {
    grid-row: 3 / span 2;
    grid-column: 4 / span 1;
}

.world-builder-panel {
    grid-row: 5 / span 10;
    grid-column: 4 / span 1;
}

.puzzle-selector-panel {
    grid-row: 1 / span 12;
    grid-column: 5;
    text-align: center;
}

/* Highlighting of these elements only happens in the lab when they're selected in the worldbuilder. */

.panel-header {
    margin: 2px;
    font-size: small;
    font-weight: bold;
}
